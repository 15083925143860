import { ApolloError } from "@apollo/client";
import checkoutMessages from "resources/messages-checkout.json";

export const calculateSubtotalAmount = (
  getCart: CartType,
  orderType?: string
) => {
  let subtotal = 0;
  if (getCart && Array.isArray(getCart.lineItems)) {
    getCart.lineItems.forEach((item: LineItemType) => {
      if (
        (!item?.displayName?.includes("RH Members Program") &&
          !item?.displayName?.includes("RH Baby & Child Gift Card") &&
          !item?.product?.displayName?.includes("RH Members Program") &&
          item?.skuType !== "membership") ||
        item?.skuType === "giftCard"
      ) {
        subtotal += item?.price?.totalPrice as number;
      }
    });
  }
  return subtotal;
};

export const rebuildOptions = (options: Maybe<LineItemOptionType[]>): any => {
  const _options = options?.map((option: LineItemOptionType, idx) => {
    const isOptionTypePriority = option?.optionTypePriority
      ? { optionTypePriority: option?.optionTypePriority }
      : {};
    return {
      id: idx.toString(),
      optionType: option.type,
      label: option.value,
      sortPriority: option?.sortPriority ?? idx,
      ...isOptionTypePriority
    } as ProductItemOption;
  });

  return _options || [];
};

export const getLastFourDigits = (value: string): string | undefined => {
  return value?.substring(value.length - 4, value.length);
};

export const getHumanReadableErrors = (responseError?: ApolloError) => {
  const errors = responseError?.graphQLErrors?.filter(
    err => err?.extensions?.code === "HUMAN_READABLE_ERROR"
  );
  return errors;
};

export const getGroupedFees = (fees: FeeType[]) => {
  const groupedFees = Array.from(
    fees.reduce(
      (m, { feeType, value }) => m.set(feeType, (m.get(feeType) || 0) + value),
      new Map()
    ),
    ([feeType, value]) => ({ feeType, value })
  );
  return groupedFees ?? [];
};

export const getMonogramFee = (fees: FeeType[] = []) => {
  const filteredFees = fees?.filter(fee => fee.feeType === "monogram_fee");

  if (!filteredFees?.length) {
    return 0;
  }

  const totalMonogramFee = filteredFees.reduce(
    (total, fee) => (fee?.unitCost || 0) * (fee?.quantity || 0) + total,
    0
  );
  return !isNaN(totalMonogramFee) ? totalMonogramFee : 0;
};

export const getAmountError = (
  price,
  amountRemainingOrTotal,
  invalidAmount = "",
  amountRequired = ""
) => {
  let errorMessages: string = "";
  if (price > amountRemainingOrTotal || price < 0) {
    errorMessages = invalidAmount || checkoutMessages.invalidAmount;
  } else if (!price) {
    errorMessages = amountRequired || checkoutMessages.amountRequired;
  } else {
    errorMessages = "";
  }

  return errorMessages || "";
};

export const getDiscountTotal = (cart: CartType): Maybe<number> => {
  return (cart?.guest?.type || "").toLowerCase() === "employee"
    ? cart?.cartPrice?.employeeSavings
    : cart?.cartPrice?.discounts?.discountCodeTotal;
};

export const getPromotionPrice = (cart: CartType): Maybe<number> => {
  const totalDiscountAmount = cart?.lineItems?.reduce((acc, lineItem) => {
    const discountAmount = lineItem?.price?.discounts?.[0]?.code
      ? lineItem?.price?.discounts?.[0]?.discountAmount || 0
      : 0;

    return acc + discountAmount;
  }, 0);

  return totalDiscountAmount || cart?.cartPrice?.employeeSavings || 0;
};

export const isStewardshipAssessment = (
  fee: Pick<FeeType, "feeType" | "state">
): boolean => fee?.feeType === "mattress_fee" && fee?.state === "OR";
